import * as _ from 'underscore';
import * as sprintf from 'sprintf';
import {trackEvent} from '../services/tracking-helper';
import currentTheme from "../react-themes/theme";

'use strict';

angular.module('app').controller('CheckoutCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', '$location', '$translate', 'CartData', 'Alerts', 'blockUI', 'AddressHelper', 'UserLocation', 'Hubs', 'PromisedElementVisible', 'PusherService', 'PartnerPixelHelper', 'UserService', 'UiStateService', function($scope, $sce, $http, $q, $rootScope, $timeout, $element, $location, $translate, CartData, Alerts, blockUI, AddressHelper, UserLocation, Hubs, PromisedElementVisible, PusherService, PartnerPixelHelper, UserService, UiStateService) {
  window.CheckoutCtrl = $scope;

  $scope.I18n = window.I18n;

  var unOnCartDataSave, unOnPaymentStageLoaded, unOnLogout, pusher, channel, cartDataListener;

  $scope.checkoutChannelName = "";
  $scope.smartPassService = window.SmartPassService;

  function constructor() {
    $scope.theme = currentTheme();
    UiStateService.inCheckout = true;

    unOnCartDataSave = $rootScope.$on('cartdata:saved', updateCartItems);
    unOnPaymentStageLoaded = $rootScope.$on('paymentStage:loaded', updateCartItems);

    unOnLogout = $rootScope.$on('user:logout', () => {
      $location.path('/').search({});
    });

    $scope.$watch('checkout.shipping_method_id', function (newValue, oldValue) {
      if (newValue && $scope.shippingMethods)
        $scope.shippingMethod = _.find($scope.shippingMethods, m => m.id == newValue);
    });

    initPusher();

    scheduleMinnigMetzgereiClosingTime();

    blockUI.start();

    $rootScope.$on("$translateChangeSuccess", () => {
      blockUI.start();
      if ($scope.stage === "payment") {
        $scope.getPaymentMethods();
      }
      $scope.getCheckout()
        .finally(() => blockUI.stop());
    });

    $timeout(() => {
      UserLocation.getUserLocation()
    }, 10);

    $scope.getOrder().then(order => {
      $scope.orderWasLoaded = false;
      $scope.getUserProfile();

      $scope.getCheckout().then(checkout => {
        $scope.orderWasLoaded = true;

        $scope.getShippingMethods();
        $scope.setBorderActiveWidths();

        blockUI.stop();

        Hubs.fetchCurrentHub().then((response) => {
          $scope.currentHub = response;
        });

        if (checkout.stage == null) {
          $scope.updateStage().then(r => {
            // setTimeout(() => { if (window.PageloadTracking) PageloadTracking.viewloadSummaryEnd() }, 100);
          });
        } else {
          // setTimeout(() => { if (window.PageloadTracking) PageloadTracking.viewloadSummaryEnd() }, 100);
        }
      });
    });

    window.loadGoogleMapsCallback(() => {
      console.info("Google Maps API loaded");
      $scope.googleMapsLoaded = true;
    });

    cartDataListener = $rootScope.$on('cartdata:loaded', () => {
      $timeout(() => {
        if ($scope.checkout && $scope.isCheckoutStageAfter('shipping') && !CartData.cart.has_valid_ship_address) {
          $scope.returnToStage('shipping').then(() => {
            Alerts.error($translate.instant('checkouts.null_ship_address'), {timeout: 10000});
            const element = document.getElementsByClassName('address-form-header');
            if (element && element[0]) element[0].scrollIntoView(false);
          });
        } else if ($scope.checkout && $scope.checkout.checkout_stage === 'payment' && $scope.checkout.state === 'payment' && !CartData.cart.has_valid_delivery_slot) {
          $scope.checkout.delivery_slot = null;
          $scope.returnToStage("delivery_time").then(() => {
            Alerts.error($translate.instant('checkouts.delivery_slot_not_available'), {timeout: 10000}).then(() => {
              const element = document.getElementsByClassName('checkout-delivery-time');
              if (element && element[0]) element[0].scrollIntoView(false);
            });
          });
        }
      }, 100);
    });

    // Temporary disable risky-code:
    // TODO!
    // $scope.$watch('CartData.cart.updated_at', function(updatedAt) {
    //   if(updatedAt == null) return;
    //   if($scope.checkout == null || $scope.checkout && $scope.checkout.state === 'payment') return;
    //   updateCartItems();
    //   $scope.getOrder();
    // });
  }

  function destructor() {
    if (unOnCartDataSave) unOnCartDataSave();
    if (unOnLogout) unOnLogout();
    if (unOnPaymentStageLoaded) unOnPaymentStageLoaded();
    if (cartDataListener) cartDataListener();

    PusherService.unsubscribe($scope.checkoutChannelName);
    UiStateService.inCheckout = false;
    window.CheckoutCtrl = null;
  }

  // Update current order and line-item dependent data, along with deliverySlot issues
  // Return to 'delivery_time' stage if slot issues detected.
  function updateCartItems() {
    blockUI.start();
    const oldItemTotal = $scope.checkout.item_total;
    $scope.getCheckout().then(() => {
      if ($scope.availableDeliverySlots) {
        if (needsDeliverySlotsUpdate($scope.checkout.item_total, oldItemTotal)) {
          $scope.getDeliverySlots().then(() => {
            populateSlotsUnfulfillable().then(() => {
              if ($scope.isCheckoutStageAfter('delivery_time') && (!$scope.deliverySlot || $scope.deliverySlot.issues)) {
                $scope.returnToStage('delivery_time')
              }
            });
          });
        } else {
          populateSlotsUnfulfillable().then(() => {
            if ($scope.isCheckoutStageAfter("delivery_time") && (!$scope.deliverySlot || $scope.deliverySlot.issues)) {
              $scope.returnToStage("delivery_time");
            }
          });
        }
      }
    }).finally(() => {
      $scope.updateOrderSummary();
      blockUI.stop();
    });
  }

  function initPusher() {
    $scope.checkoutChannelName = `user-channel-${getPrivateChannelName()}`;

    //console.log($scope.checkoutChannelName);

    $scope.notificationsCheckoutChannel = PusherService.bind($scope.checkoutChannelName, 'delivery-slots-expired', function (data) {
      // console.log("delivery-slots-expired: ");
      if($scope.stage == 'delivery_time'){
        $scope.getDeliverySlots();
      } else if($scope.stage == 'payment') {
        // Reload angular scope to apply all order changes
        $scope.$apply();
      }
    });
  }

  /**
   * Fills slot.issues and slot.problem_line_items with generated data
   * about order line items that cannot be shipped on that specific date
   *
   * slot.issues - generic messages with issues of a specific slot
   * slot.problem_line_items - array of line items that cannot be fulfilled for the slot
   */
  function populateSlotsUnfulfillable() {
    return $q(function(resolve, reject) {
      _.each($scope.availableDeliverySlots, slot => {
        if ((slot.excluded_supplier_ids && slot.excluded_supplier_ids.length > 0) || (slot.restricted_products_ids && slot.restricted_products_ids.length > 0)) {
          slot.problem_line_items = _.filter($scope.checkout.line_items, (item) => {
            // There's a problem when an item belongs to an excluded supplier or its hub isn't supported by the slot
            return slot.excluded_supplier_ids.includes(item.sourcing_supplier_id) || slot.restricted_products_ids?.includes(item.product_id);
          });

          if (slot.problem_line_items.length > 0) {
            if (slot.problem_line_items.length == $scope.checkout.line_items.length) {
              slot.issues = ['all_products_unfulfillable'];
            } else {
              slot.issues = ['some_products_unfulfillable'];
            }
          }
        }
      });

      if (window.CheckoutDeliveryTimeCtrl.selectedSlot) {
        // Only to trigger the watcher and rerender the slot with new restrictions
        $scope.checkout.delivery_slot_id = window.CheckoutDeliveryTimeCtrl.selectedSlot.id;
      }

      resolve();
    });
  }

  function scheduleMinnigMetzgereiClosingTime() {
    const friday = 5;
    const daysInWeek = 7;
    const minningMetzgereiFridayClosingTime = [11, 45, 0, 0];
    const timeNow = new Date();
    const daysUntilFriday = (friday - timeNow.getDay() + daysInWeek) % daysInWeek;
    const fridayClosingTime = new Date(timeNow);
    fridayClosingTime.setDate(timeNow.getDate() + daysUntilFriday);
    fridayClosingTime.setHours(...minningMetzgereiFridayClosingTime);
    const timeUntilClosing = fridayClosingTime - timeNow;
    if (timeUntilClosing) {
      $timeout($scope.getDeliverySlots, timeUntilClosing);
    }
  }

  /**
   * Fills slot.availablePromos with present both in slot.available_promo_ids and the global $scope.availableDeliveryPromos
   */
  function populatePromotions() {
    $scope.slotsWithPromoExist = false;

    _($scope.availableDeliverySlots).each((slot) => {
      slot.availablePromos = _(slot.available_promo_ids).filter( (s) => $scope.availableDeliveryPromos.indexOf(s) > -1 )
      if (slot.availablePromos.length > 0)
        $scope.slotsWithPromoExist = true
    })
  }

  /**
   * Causes a full stage rollback all the way up to the delivery mode,
   * in case the checkout is somehow broken.
   *
   * @param checkout
   */
  function migrateOldCheckout(checkout) {
    if (checkout.delivery_mode == null && (checkout.state == 'cart' || checkout.state == 'address' || checkout.state == 'delivery' || checkout.state == 'payment' || checkout.state == 'recommend')) {
      $scope.returnToStage('shipping', false).then(() => {
        blockUI.stop();

        // Due to stage reload, user profile and support data might not get loaded properly
        // and needs to be reloaded
        $scope.getShippingMethods();

        $scope.getUserProfile();

        $scope.setBorderActiveWidths();

        Hubs.fetchCurrentHub().then((response) => {
          $scope.currentHub = response;
        });
      })

      return true;
    }

    return false;
  }

  function trackGaCheckoutState() {
    if (window.ga) {
      var stepNumber = 2;
      var stepName = "Delivery Mode"

      if ($scope.stage == "shipping") {
        stepName = "Address"
        stepNumber = 2;
      } else if ($scope.stage == "delivery_time") {
        stepName = "Delivery"
        stepNumber = 3;
      } else if ($scope.stage == "payment") {
        stepName = "Payment"
        stepNumber = 4;
      }

      window.ga('ec:setAction', 'checkout', {'step': stepNumber});
      window.ga('send', 'event', 'Ecommerce', 'Checkout', stepName);
    }
  }

  // Returns wether DeliverySlots should be fetched again
  // Manages potential cart changes during or after delivery_time stage
  function needsDeliverySlotsUpdate(newItemTotal, oldItemTotal) {
    if ($scope.isCheckoutStageBefore("delivery_time")) return false;
    if ($scope.smartPassService.hasActiveSubscription) return true;
    if (!$scope.maxPriceTiers || $scope.maxPriceTiers.length === 0) return false;
    if (belowPriceTier(oldItemTotal) !== belowPriceTier(newItemTotal)) return true;
  }

  function belowPriceTier(itemTotal) {
    var response = _($scope.maxPriceTiers).select((maxAmount) => {
      return itemTotal <= maxAmount;
    })[0];
    return response || -1;
  }

  // Public members

  $scope.AddressHelper = AddressHelper;

  // TODO: Order is just needed for the number, the actual workable payload is in the 'checkout' object.
  $scope.order = {
    number: window.currentOrderNumber,
    token: window.currentOrderToken,
    id: window.currentOrderId,
    user_id: window.currentUserId
  };
  $scope.checkout = null;

  /**
   * User profile data (saved addressed, credit cards, preferences, etc.)
   *
   * @type {object}
   */
  $scope.userProfile = null;

  $scope.userLocation = null;

  /**
   * Placeholder for known shipping methods collection.
   *
   * @type {Array}
   */
  $scope.shippingMethods = null;

  $scope.shippingMethod = null;

  /**
   * Shipping method recommended by the system for the order ship address
   *
   * @type {object}
   */
  $scope.recommendedShippingMethod = null;

  /**
   * Available pre-saved address. Loaded by getProfile() and taken from userProfile.
   *
   * @type {array}
   */
  $scope.preferenceShipAddresses = null;
  $scope.preferenceBillAddresses = null;

  /**
   * Currently used shipping address.
   *
   * @type {object}
   */
  $scope.shipAddress = null;

  /**
   * Currently used billing address.
   *
   * @type {object}
   */
  $scope.billAddress = null;

  $scope.availablePickupPoints = null;

  $scope.pickupPoint = null;

  /**
   * Available delivery slots. Loaded by getDeliverySlots().
   *
   * @type {Array}
   */
  $scope.availableDeliverySlots = null;

  /**
   * Selected delivery slot
   * @type {null}
   */
  $scope.deliverySlot = null;

  /**
   * Collection of available payment methods
   *
   * @type {Array}
   */
  $scope.availablePaymentMethods = null;

  $scope.paymentMethod = null;

  $scope.stage = 'loading';

  /**
   * Utility flag for loading google maps
   *
   * @type {boolean}
   */
  $scope.googleMapsLoaded = false;

  // Some 'constants'

  $scope.CHECKOUT_STATES = ['cart', 'address', 'delivery', 'recommend', 'payment', 'payment_confirmation', 'complete'];
  $scope.CHECKOUT_STAGES = ['loading', 'delivery_mode', 'shipping', 'delivery_time', 'recommend', 'payment', 'complete'];

  $scope.STRINGS = {
    guest_customer: $translate.instant('checkouts.guest_customer')
  };

  $scope.adminMode = UserService.isAdmin;

  /**
   * Special selector to override capacity reserve mode, that
   * is used to populate slot capacities and saturation by the server API
   *
   * @type {string}
   */
  $scope.adminCustomerGroup = '';

  // region Checkout and order attributes driving this interface
  /**
   * Keeps the selected shipping type (i.e. delivery vs pickup)
   *
   * @type {string}
   */
  // $scope.checkout.delivery_mode;
  // endregion

  /**
   * Expands the specified checkout stage, if the transition is valid
   *
   * @param stage
   * @param options
   */
  $scope.showStage = function (stage, options) {

  };

  /**
   * Sets up green progress bars in checkout stage section captions
   */
  $scope.setBorderActiveWidths = function () {
    PromisedElementVisible.promise($element, '.checkout-section-heading .header .text').then((el) => {
      $('.border-active').each(function (i, e) {
        var width = $(e).prev('.header').find('.text').innerWidth();
        width = $('html').width() < 767 ? '91%' : width + 40;

        $(e).width(width);
      })
    });
  };

  // To use instead of $scope.CHECKOUT_STAGES and be able to use
  // an accurate {{ $scope.stageToIndex() }} value in the view.
  // (pickup orders have one less step in the view since they skip 'shipping')
  $scope.checkoutStages = function () {
    var stages = _($scope.CHECKOUT_STAGES).reject(function (i) {
      return i == 'recommend' || i == 'delivery_mode'
    });

    return stages;

    // if ($scope.checkout && $scope.checkout.delivery_mode == "deliver") {
    //   return stages;
    // } else {
    //   return _(stages).reject(function (i) { return i == 'shipping' });
    // }
  };

  $scope.calculateStage = function () {
    if ($scope.checkout == null) {
      return 'loading';
    }

    // TODO: based on order state and local flags, calculate the order display stage
    if ($scope.checkout.shipping_method_id == null && $scope.checkout.state != 'address' && $scope.checkout.delivery_mode == null) {
      return 'delivery_mode';
    }

    if ($scope.isCheckoutStateBefore('delivery') && $scope.checkout.delivery_mode != null) {
      return 'shipping';
    }

    if ($scope.checkout.state == 'delivery' && ($scope.checkout.ship_address != null || $scope.checkout.pickup_point_id != null)) {
      return 'delivery_time';
    }

    if ($scope.checkout.state == 'payment' && $scope.checkout.shipping_method_id != null && $scope.checkout.delivery_slot_id != null) {
      return 'payment';
    }

    return $scope.checkout.checkout_stage || "loading";
  };

  $scope.updateStage = function () {
    return $q(function (resolve, reject) {
      $scope.stage = $scope.calculateStage();

      if (window.hj) {
        window.hj('vpv', `/checkout/${$scope.stage}`);
      }

      ahoy.track(`checkout-stage-${$scope.stage}`, {
        order_id: $scope.checkout.id,
        user_id: $scope.checkout.user_id,
        total: $scope.checkout.total,
        channel: window.xSessionChannel
      });

      try {
        trackGaCheckoutState()
      } catch (e) {
        console.error(e);
        // if (window.airbrake) window.airbrake.notify(e)
      }

      if ($scope.stage == 'shipping') {
        $scope.updateCheckout({
          state: 'address',
          checkout_stage: 'shipping',
          delivery_mode: $scope.checkout.delivery_mode
        }, true).then(c => resolve(c), e => reject(e));
      } else if ($scope.stage == 'delivery_mode') {
        $scope.updateCheckout({
          state: 'address',
          checkout_stage: 'delivery_mode'
        }, true).then(c => resolve(c), e => reject(e));
      } else if ($scope.stage == 'delivery_time') {
        if ($scope.availableDeliverySlots == null) {
          $scope.getDeliverySlots().then(r => {
            $scope.updateCheckout({
              state: 'delivery',
              checkout_stage: 'delivery_time'
            }, true).then(c => resolve(c), e => reject(e));
          });
        } else {
          $scope.getDeliverySlots(); // Force-update the delivery slot collection
          $scope.updateCheckout({
            state: 'delivery',
            checkout_stage: 'delivery_time'
          }, true).then(c => resolve(c), e => reject(e));
        }
      } else if ($scope.stage == 'payment') {
        $scope.getPaymentMethods(); // Force-update the payment method collection
        $scope.updateCheckout({checkout_stage: 'payment'}, true).then(c => resolve(c), e => reject(e));
      }
    })
  };

  /**
   * Attempts a rollback to a previous checkout stage with an optional
   * confirmation prompt.
   *
   * @param state
   */
  $scope.returnToStage = function (stage, confirm) {
    return $q(function (resolve, reject) {
      // Don't allow the transition if the requested stage is AFTER the current one
      if (!$scope.isCheckoutStageAfter(stage)) {
        reject({error: `Invalid rollback transition from ${$scope.stage} to ${stage}`, code: 400})
      }

      var doRollback = () => {
        if (stage == 'shipping') {
          blockUI.start();
          $scope.updateCheckout({state: 'address', checkout_stage: 'shipping'}, true).then(() =>
            $scope.updateStage().then((c) => {
              blockUI.stop()
              resolve(c)
            }, (e) => {
              blockUI.stop()
            }))
        } else if (stage == 'delivery_time') {
          blockUI.start();
          $scope.updateCheckout({
            state: 'delivery',
            delivery_slot_id: null,
            checkout_stage: 'delivery_time'
          }, true).then(() =>
            $scope.updateStage().then((c) => {
              blockUI.stop()
              resolve(c)
            }, (e) => {
              blockUI.stop()
            }))
        } else if (stage == 'delivery_mode') {
          blockUI.start();
          $scope.updateCheckout({
            state: 'cart',
            checkout_stage: 'delivery_mode',
            delivery_mode: null,
            bill_address_id: null,
            ship_address_id: null,
            pickup_point_id: null,
            shipping_method_id: null,
            delivery_slot_id: null
          }, true).then(() => {
            $scope.updateStage().then((c) => {
              blockUI.stop()
              resolve(c)
            }, (e) => {
              blockUI.stop()
            })
          })
        }
      }

      // Handle optional confirm parameter
      if (confirm) {
        if (window.confirm(confirm)) {
          doRollback();
        } else {
          reject({error: `Cancelled`, code: 200})
        }
      } else {
        doRollback();
      }
    });
  };

  $scope.updateOrderSummary = function () {
    if ($('.checkout-order-contents').length > 0) {
      $.get(Routes.spree_checkout_current_order_info_path(), function (data, status, xhr) {
        $('.checkout-order-contents').html(data);
      });
    }
  };

  /**
   * Load user profile data
   */
  $scope.getUserProfile = function () {
    return $q(function (resolve, reject) {
      $http.get('/api/users/current.json').then(function (response) {
        $scope.userProfile = response.data;

        if ($scope.userProfile.phone_verified_at == null) {
          Alerts.error($translate.instant('checkouts.phone_verification_required'));
          $location.path('/cart');
          UserService.verifyPhoneIfNeeded();
        }

        if ($scope.userProfile.preference_addresses) {
          $scope.preferenceShipAddresses = _.chain($scope.userProfile.preference_addresses).filter(a => a.preferred_for == "" || a.preferred_for == "ship").sortBy(address => {
            if (address.updated_at)
              return moment(address.updated_at).unix();
            else if (address.created_at)
              return moment(address.created_at).unix();
            return 0
          }).reverse().value();
          $scope.preferenceBillAddresses = _.filter($scope.userProfile.preference_addresses, (a) => a.preferred_for == "" || a.preferred_for == "bill")
        };

        if ($scope.userProfile.credit_cards) {
          $scope.userProfile.credit_cards = $scope.userProfile.credit_cards.map(card => {
            return {...card, month: `0${card.month}`.slice(-2)}
          });
        }

        resolve($scope.userProfile);
      })
    })
  };

  /**
   * Load user profile data
   */
  $scope.getOrder = function () {
    $scope.isLoadingOrder = true;

    return $q(function (resolve, reject) {
      $http.get(sprintf("/api/frontend/orders/current.json?locale=%s", I18n.locale), {
        params: {
          number: window.currentOrderNumber,
          order_token: window.currentOrderToken,
          exclude_line_items: 't',
          exclude_adjustments: 't'
        }
      }).then(function (response) {
        // Sort line items by name
        if (response.data.order && response.data.order.line_items) {
          response.data.order.line_items = _.sortBy(response.data.order.line_items, function (li) {
            return li.product.name
          });
        }

        $scope.order = response.data.order;
        resolve($scope.order);
      }).finally(() => {
        $scope.isLoadingOrder = false;
      });
    })
  };

  /**
   * Load user profile data
   */
  $scope.getCheckout = function () {
    $scope.isLoadingCheckout = true;
    blockUI.start();
    return $q(function (resolve, reject) {
      $http.get(sprintf("/api/checkouts/%s.json?order_token=%s&locale=%s", $scope.order.number, $scope.order.token, I18n.locale)).then(function (response) {
        if ($scope.checkout == null) $scope.checkout = {};
        angular.extend($scope.checkout, response.data);

        $scope.stage = $scope.checkout.checkout_stage;
        $scope.shipAddress = $scope.checkout.ship_address;
        $scope.billAddress = $scope.checkout.bill_address;

        // Fix for dates that are not compatible with 3x combo-date-picker
        // https://farmyag.atlassian.net/browse/IM-1651
        if ($scope.billAddress && $scope.billAddress.birth_date)
          $scope.billAddress.birth_date = AddressHelper.sanitizeBirthDate($scope.billAddress.birth_date);


        if ($scope.checkout.pickup_point_id) {
          if ($scope.availablePickupPoints && $scope.pickupPoint == null)
            $scope.pickupPoint = _.find($scope.availablePickupPoints, (p) => p.id == $scope.checkout.pickup_point_id)
          else
            $scope.getPickupPoints().then((r) => {
              $scope.pickupPoint = _.find($scope.availablePickupPoints, (p) => p.id == $scope.checkout.pickup_point_id)
            })
        }

        // Automatically refresh order summary
        $scope.getOrderSummary();
        $rootScope.$broadcast('checkout:loaded', $scope.checkout)
        updateAdjustments();
        setMaxShippingCost();
        setShouldShowAdjustments();

        if (!migrateOldCheckout($scope.checkout)) {
          resolve($scope.checkout);
        }
      }, function(e) {
        // Handle 'minimum order value' response
        const valueErrors = [$translate.instant("errors.minimum_order_value_error")];
        if (e.data.friendly_errors.length && e.data.friendly_errors[0]) {
          Alerts.error(e.data.friendly_errors[0], {timeout: 5000});
          if (e.data.full_messages && _(valueErrors).any(function(i) {
            return e.data.full_messages.indexOf(i) > -1;
          })) {
            $timeout(() => {
              console.log("Validations failed, redirecting back to cart");
              location.assign("/cart")
            }, 4000);
          }
        }
      }).finally(() => {
        $scope.isLoadingCheckout = false;
        blockUI.stop();
      });
    });
  };

  $scope.updateCheckout = function (data, noNext, noLineItems) {
    if (Rails.env == 'development') {
      // console.log("Updating checkout with data:", data, "noNext:", noNext, "from:", (new Error("trace")).stack);
      console.log("Updating checkout with data:", data, "noNext:", noNext);
    }

    $scope.isUpdating = true;

    return $q(function (resolve, reject) {
      $scope.checkout.friendlyErrors = null;

      if (data == null) data = $scope.checkout;
      else {
        data = {order: data};
      }

      // Never request new line items by default
      if (noLineItems === undefined) noLineItems = true;

      $http.put(sprintf(`/api/checkouts/${$scope.checkout.number}.json?order_token=${$scope.order.token}&locale=${I18n.locale}${noNext ? "&no_state_transition=true" : ""}${noLineItems ? "&no_line_items=t" : ""}`), data).then(function (response) {
        if ($scope.checkout == null) $scope.checkout = {};
        angular.extend($scope.checkout, response.data);

        $scope.shipAddress = $scope.checkout.ship_address;
        $scope.billAddress = $scope.checkout.bill_address;
        $scope.stage = $scope.checkout.checkout_stage;

        // Fix for dates that are not compatible with 3x combo-date-picker
        // https://farmyag.atlassian.net/browse/IM-1651
        if ($scope.billAddress && $scope.billAddress.birth_date)
          $scope.billAddress.birth_date = AddressHelper.sanitizeBirthDate($scope.billAddress.birth_date);

        $scope.isUpdating = false;

        $scope.getOrderSummary();
        updateAdjustments();
        setMaxShippingCost();
        setShouldShowAdjustments();

        //
        // Complete checkout, upon respective state
        //
        if ($scope.checkout.state == 'complete') {
          ahoy.track('checkout-complete-reached', {order_id: $scope.checkout.id, user_id: $scope.checkout.user_id});

          cartDataListener();

          PartnerPixelHelper.checkoutConversionTracking($scope.checkout);

          $timeout(() => {
            blockUI.stop();
            $rootScope.$broadcast('checkout:complete');
            $location.url(`/orders/${$scope.checkout.number}/thankyou?order_token=${$scope.checkout.token}`);
          }, 300);
        } else {
          blockUI.stop();
          resolve($scope.checkout);
        }
      }, (e) => {
        if (e.data) {
          if (e.data.friendly_errors) {
            $scope.checkout.friendlyErrors = e.data.friendly_errors;

            if (e.data.friendly_errors.length && e.data.friendly_errors[0]) {
              if (!e.data?.errors?.coupon_code) {
                const errorMessage = e.data.errors && Object.values(e.data.errors)[0][0];
                Alerts.error(errorMessage ? errorMessage : e.data.friendly_errors[0], {timeout: 7000});
              }
            }
          }
        }

        $scope.isUpdating = false;

        blockUI.stop(); // Force-stop blockUI

        reject(e);
      })
    });
  };

  $scope.getShippingMethods = function () {
    return $q(function (resolve, reject) {
      $http.get(`/api/checkouts/${$scope.checkout.number}/shipping_methods.json?locale=${I18n.locale}&order_token=${$scope.checkout.token}`).then(function (response) {
        $scope.shippingMethods = response.data.shipping_methods;

        // Assign the selected the shipping method, if it's already known
        if ($scope.checkout.shipping_method_id && $scope.shippingMethods)
          $scope.shippingMethod = _.find($scope.shippingMethods, (m) => m.id == $scope.checkout.shipping_method_id);

        if (response.data.recommended_for_address)
          $scope.recommendedShippingMethod = _.find($scope.shippingMethods, (m) => m.id == response.data.recommended_for_address);

        resolve($scope.shippingMethods);
      }, (e) => reject(e))
    })
  };

  $scope.getPaymentMethods = function () {
    return $q(function (resolve, reject) {
      $http.get(`/api/checkouts/${$scope.checkout.number}/available_payment_methods.json?locale=${I18n.locale}&order_token=${$scope.checkout.token}`).then(function (response) {
        $scope.availablePaymentMethods = response.data.payment_methods;
        resolve($scope.availablePaymentMethods);
      }, (e) => reject(e));
    })
  };

  $scope.getDeliverySlots = function () {
    $scope.loadingDeliverySlots = true;

    let params = {
      no_supplier_restrictions: 't',
      no_special_date_restrictions: 't',
      locale: I18n.locale,
      order_token: $scope.checkout.token
    };

    if ($scope.adminCustomerGroup != null && $scope.adminCustomerGroup != '')
      params.admin_customer_group = $scope.adminCustomerGroup;

    return $q(function (resolve, reject) {
      $http.get(`/api/checkouts/${$scope.checkout.number}/delivery_slots.json`, { params: params }).then(function (response) {
        if (!response.data.not_available_delivery_slot) {
          $scope.availableDeliverySlots = response.data.delivery_slots;
          $scope.defaultSelectedSlotIds = response.data.default_selected_slot_ids;
          $scope.maxPriceTiers = response.data.max_price_tiers;
          $scope.availableDeliveryPromos = response.data.available_promo_ids;
          populatePromotions();
          populateSlotsUnfulfillable();
          resolve($scope.availableDeliverySlots);
        }
      }, (e) => reject(e)).finally(() => $scope.loadingDeliverySlots = false);
    });
  };

  $scope.getPickupPoints = function () {
    return $q(function (resolve, reject) {
      $http.get(`/api/checkouts/${$scope.checkout.number}/pickup_points.json?&locale=${I18n.locale}&order_token=${$scope.checkout.token}`).then(function (response) {
        $scope.availablePickupPoints = response.data.pickup_points;
        resolve($scope.availablePickupPoints);
      }, (e) => reject(e));
    });
  };

  $scope.updateCheckoutDeliverySlot = function (deliverySlot) {
    return $q(function (resolve, reject) {

      var params = {
        'id': $scope.checkout.number,
        'order_token': $scope.checkout.token,
        'order': {'delivery_slot_id': deliverySlot.id},
        'delivery_slot_id': deliverySlot.id
      };

      $http.put(`/api/checkouts/${$scope.checkout.number}/update_delivery_slot.json?no_line_items=t&no_adjustments=t&no_min_order_value=t&no_shipments=t&no_payments=t&no_state_specific=t`, params)
        .then(function (response) {
          if (_.isEmpty(response.data.order.ship_address) && _.isEmpty(response.data.order.pickup_point)) {
            $scope.returnToStage('shipping').then(() => {
              Alerts.error($translate.instant('checkouts.null_ship_address'), {timeout: 10000});
              const element = document.getElementsByClassName('address-form-header');
              if (element && element[0]) element[0].scrollIntoView(false);
              reject();
            });
          }
          else {
            $scope.deliverySlot = deliverySlot;
            resolve(response.data);

            // Update cart totals display
            setTimeout(() => {
              if (window.CartData) window.CartData.load()
            }, 100);
          }
        }, function (error) {
          $scope.deliverySlot = null;
          Alerts.error(errorMessage(error), {timeout: 5000});
          reject(error);
        }).finally(function () {
          $scope.isUpdatingDeliverySlot = false;
          blockUI.stop();
      });
    });
  };

  $scope.updateCheckoutPickupPoint = function (pickupPoint) {
    return $q(function (resolve, reject) {
      $scope.isPickupPoint = true;

      var params = {
        'id': $scope.checkout.number,
        'order_token': $scope.checkout.token,
        'order': {'pickup_point_id': pickupPoint.id}
      };

      $http.put(`/api/checkout/${$scope.checkout.number}/update_pickup_point.json?no_line_items=t`, params)
        .then(function (response) {
          $scope.pickupPoint = pickupPoint;
          resolve(response.data);
        }, function (error) {
          // $scope.pickupPoint = null;
          Alerts.error(errorMessage(error), {timeout: 5000});
          reject(e);
        }).finally(function () {
        $scope.isPickupPoint = false;
        blockUI.stop();
      });
    });
  };

  $scope.getOrderSummary = function () {
    $http.get(window.Routes.spree_checkout_current_order_info_path()).then(function (response) {
      $('.current-order-wrapper .checkout-order-contents').html(response.data);
    });
  };

  $scope.isCheckoutStateBefore = function (state) {
    return $scope.stateToIndex($scope.checkout.state) < $scope.stateToIndex(state);
  };

  /**
   * Returns TRUE if the current checkout stage is BEFORE the specified stage
   *
   * @param stage
   * @returns {boolean}
   */
  $scope.isCheckoutStageBefore = function (stage) {
    if ($scope.checkout == null || $scope.checkout.checkout_stage == null) return true;
    return $scope.stageToIndex($scope.checkout.checkout_stage) < $scope.stageToIndex(stage);
  };

  /**
   * Returns TRUE if the the current stage is AFTER the specified checkout stage
   *
   * @param stage
   * @returns {boolean}
   */
  $scope.isCheckoutStageAfter = function (stage) {
    if ($scope.checkout == null || $scope.checkout.checkout_stage == null) return false;
    return $scope.stageToIndex($scope.checkout.checkout_stage) > $scope.stageToIndex(stage);
  };

  $scope.stateToIndex = function (state) {
    return $scope.CHECKOUT_STATES.indexOf(state);
  };

  $scope.stageToIndex = function (stage) {
    return $scope.checkoutStages().indexOf(stage);
  };

  /**
   * Ensures the promise code is executed only when the google maps API is available
   * @returns {*}
   */
  $scope.getMapsApi = function () {
    return $q(function (resolve, reject) {
      if ($scope.googleMapsLoaded) {
        return resolve();
      } else {
        // Wait until it's loaded
        $timeout(() => $scope.getMapsApi().then((r) => $timeout(() => resolve(), 200)), 100);
      }
    });
  };

  $scope.backToCart = function () {
    $timeout(() => {
      window.location.href = '/cart'
    }, 1);
  };

  $scope.getCurrentHub = function () {
    return $q((resolve, reject) => {
      if ($scope.currentHub) {
        resolve($scope.currentHub)
      } else {
        Hubs.fetchCurrentHub().then(hub => {
          $scope.currentHub = hub;
          resolve($scope.currentHub);
        })
      }
    })
  };

  $scope.getTotalPaymentPending = function() {
    if ($scope.checkout.payment_total) {
      return parseFloat($scope.checkout.total_including_surcharges) - parseFloat($scope.checkout.payment_total);
    } else {
      return parseFloat($scope.checkout.total_including_surcharges);
    }
  };

  $scope.trackAddPaymentInfo = function(paymentMethodName) {
    trackEvent(
      'add_payment_info',
      {
        products: $scope.checkout.line_items,
        checkout: {...$scope.checkout, payment_method_name: paymentMethodName || ''}
      }
    );
  };

  $scope.removeDiscountAdjustment = function(adjustment) {
    blockUI.start();

    return $q((resolve, reject) => {
      adjustment.isUpdating = true;

      $http.post("/api/frontend/orders/" + $scope.checkoutCtrl.checkout.id.toString() + "/remove_adjustment.json", {adjustment_id: adjustment.id}).then((response) => {
        $scope.checkoutCtrl.getUserProfile();
        $scope.checkoutCtrl.getPaymentMethods();

        $scope.checkoutCtrl.getCheckout().then((r) => {
          adjustment.isUpdating = false;
          blockUI.stop();
          resolve(r);
        });
      }, (e) => {
        blockUI.stop();
        reject(e);
      }).finally(function() {
        if (adjustment) {
          adjustment.isUpdating = false;
        }
      });
    });
  };

  $scope.onAdjustmentDescriptionClick = function(adjustment) {
    if (adjustment.permabox_adjustment) OrderHelper.showPermaboxDepositExplanationModel();
    if (adjustment.code === ":handling_fee") OrderHelper.showHandlingFeeModal($scope.checkout);
  };

  // Initialize

  // initPusher();
  $scope.$on('$destroy', destructor);
  constructor();

  // Private
  function getPrivateChannelName(userData) {
    if (userData == null) {
      if (window.UserService.currentUser == null || window.UserService.currentUser.id == null)
        console.error("No user data available");
        // throw new Error("No user data available");
      return window.UserService.currentUser.id + '-' + btoa(window.UserService.currentUser.email);
    } else {

      return userData.id + '-' + btoa(userData.email);
    }
  }

  function updateAdjustments() {
    $scope.checkout.adjustments.forEach(adjustment => {
      const withModal = [':handling_fee'];
      if (withModal.includes(adjustment.raw_label || adjustment.code))
        adjustment.clickable = true;
    })
  }

  function setShouldShowAdjustments() {
    $scope.shouldShowAdditionalFees = shouldShowAdditionalFees();

    $scope.shouldShowAdjustments = ($scope.checkout.non_zero_adjustments && $scope.checkout.non_zero_adjustments.length > 0) ||
      $scope.checkout.weight_control_surcharge_total > 0 ||
      $scope.shouldShowAdditionalFees;
  }

  // Logic for "shipping fee"
  function shouldShowAdditionalFees() {
    // Remove this line when "handling fee" feature is open to everybody.
    if (UserService.isLoggedIn && !UserService.currentUser.should_apply_handling_fee)
      return false;

    let should = $scope.maxShippingCost > 0 &&
      $scope.checkout.item_total < $scope.checkout.min_value_for_free_shipping;

    should = should || $scope.checkout.handling_fee > 0;

    return should
  }

  function setMaxShippingCost() {
    $scope.maxShippingCost = $scope.checkout.approximate_shipping_cost ?? 0;
  }
}]);
