import {useState, useEffect} from 'react';
import currentTheme from "../react-themes/theme";

const theme = currentTheme();
const windowSteps = theme.breakPoints;

const getWindowStep = (width) => {
  const targetValue = Object
    .values(windowSteps)
    .filter(value => value <= width)
    .sort((x, y) => y - x)[0];

  const step = targetValue
    ? Object.keys(windowSteps).find(key => windowSteps[key] === targetValue)
    : "xl";

  return step;
};

const useWindowTools = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowStep, setWindowStep] = useState(getWindowStep(window.innerWidth));
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);

  const widthUnderStep = (step, included = false) => {
    const steps = Object.keys(windowSteps);
    return included
      ? steps.indexOf(windowStep) <= steps.indexOf(step)
      : steps.indexOf(windowStep) < steps.indexOf(step);
  };

  const widthOverStep = (step, included = false) => {
    const steps = Object.keys(windowSteps);
    return included
      ? steps.indexOf(windowStep) >= steps.indexOf(step)
      : steps.indexOf(windowStep) > steps.indexOf(step);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowStep(getWindowStep(window.innerWidth));
    };

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isMobile = windowWidth < theme.breakPoints.sm;

  return {windowWidth, windowStep, isMobile, scrollPosition, widthOverStep, widthUnderStep};
};

export default useWindowTools;
