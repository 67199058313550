import React, {useState, useEffect, useRef, lazy, useCallback} from 'react';
import {apiLoadProducts} from "../shared-services/product-fetcher";
import productParser from "../react/products/productParser";
import currentTheme from "../react-themes/theme";
import {RjsProductItem} from "./products/RjsProductItem";
import RjsProductTable from "./RjsProductTable";
import {Button} from "react-bootstrap3";
import styled, {ThemeProvider} from "styled-components";
import {acceptNgRef, invalidateNgRef} from "./shared/ngRef";
import * as placeholders from "./products/RjsProductItemPlaceholders";
import {useSelector} from "../shared-services/redux-tiny";
import {t} from '../shared-services/translate';
import {trackPageView, trackEvent} from '../services/tracking-helper';
import PaginationConfig from './tools/PaginationConfig';
import {useLocation} from "react-router-dom";
import useWindowTools from "../react-hooks/useWindowTools";
import useNamedSets from "../react/products/useNamedSets";

const parseProducts = productParser();
const theme = currentTheme();

export const RjsProductList = (props) => {
  const reactLocation = useLocation();
  const rootRef = useRef();
  const fetchNamedSet = useNamedSets();
  const paginator = props.paginator;
  const [isLoading, setLoading] = useState(props.isLoading);
  const [products, setProducts] = useState(parseProducts(props?.products || []));
  const limitTo = parseInt(props.limitTo).toString() == props.limitTo ? parseInt(props.limitTo) : null;
  const callerLocation = props.callerLocation || null;

  const {currentZipcode, currentHub} = useSelector(state => state.session);
  const currentHubId = currentHub ? currentHub.id : null;

  const parentNodeId = props.parentNodeId; // Allows to define a parent for initial width purposes.

  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [filters, setFilters] = useState({});

  const {isMobile} = useWindowTools();
  const hideMoreButton = props.hideMoreButton;

  const setVisibleProducts = (prods = products) => {
    if (rootRef?.current && prods) setProducts(parseProducts(prods));
  };

  useEffect(() => {
    const handleFilterUpdate = (event, newFilters) => {
      setFilters(newFilters);
    };

    window.addEventListener("filtersUpdated", handleFilterUpdate);

    return () => {
      window.removeEventListener("filtersUpdated", handleFilterUpdate);
    };
  }, []);

  useEffect(() => {
    if (window.serializedFilters) {
      setFilters(window.serializedFilters);
      setCurrentPage(1);
    }
  }, [window.serializedFilters]);

  const loadMoreProducts = async(filters) => {
    if (!hasMoreProducts) return;

    try {
      setLoading(true);
      const newPage = currentPage + 1;
      const currentTaxonId = window.getCurrentTaxonId ? window.getCurrentTaxonId() : null;
      const supplierId = window.currentSupplierId;

      const params = {
        keywords: props.keywords,
        page: newPage,
        per_page: PaginationConfig.itemsPerPage,
        sort_mode: window.taxonSortMode || "relevancy",
        template: "product_in_catalog",
        filters: "t",
        zipcode: currentZipcode,
        locale: I18n.locale,
        express_delivery: props.expressDelivery === true ? "t" : "f",
        "s[taxon_ids][]": currentTaxonId,
        "s[supplier_id][]": supplierId,
        "s[hub_ids]": currentHubId,
        ...filters
      };

      const result = await apiLoadProducts(params);

      if (result.products && result.products.length > 0) {
        if (rootRef?.current) {
          setVisibleProducts([...products, ...result.products]);
          setCurrentPage(prevPage => prevPage + 1);
        }
      } else {
        if (rootRef?.current) setHasMoreProducts(false);
      }
    } catch (error) {
    } finally {
      if (rootRef?.current) setLoading(false);
    }
  };

  useEffect(() => {
    if (paginator && paginator.pagination) {
      setHasMoreProducts(products.length < paginator.pagination.total_count);
    }
  }, [products, paginator]);

  let parent;
  const getParent = () => {
    parent = parentNodeId ? (document.querySelector(`#${parentNodeId}`)) || rootRef.current?.closest(`.${parentNodeId}`) : null;
    parent = parent || document.querySelector(".container.container-fluidish") || document.querySelector("#main-container") || document.body;
  };

  const getDimensions = () => {
    getParent();

    const newDimensions = {
      width: parent.offsetWidth,
      height: parent.offsetHeight
    };

    return newDimensions;
  };

  const [dimensions, setDimensions] = useState(getDimensions());

  const updateDimensions = () => {
    const newDimensions = getDimensions();
    if (dimensions.width !== newDimensions.width) {
      setDimensions(newDimensions);
    }
  };

  const getCardWidth = () => {
    const defaultWidth = theme.product_cards[isMobile ? "mobile_cards" : "full"]?.cardWidth;
    return theme.product_cards[props.layout]?.cardWidth || defaultWidth;
  };

  const [cardWidth, setCardWidth] = useState(getCardWidth());

  const debug = localStorage.debugProductLists;

  const getLayout = (layout) => {
    const response = window.isMobile && !(props.layout && (props.layout.includes("list") || props.layout.includes("search") || props.layout.includes("cart"))) ? "mobile_cards" : layout || props.layout || "full";
    return response === "smart" ? "full" : response;
  };

  const isBot = window.browserIsBot || window.prerenderAgent;

  const setListLayout = (layout) => {
    setLayout(getLayout(layout));
  };

  const updateLoading = (newIsLoading) => {
    setTimeout(() => {
      setLoading(newIsLoading);
    }, 2000);
    // setLoading(isLoading)
  };

  const [layout, setLayout] = useState(getLayout());

  function onDebugSwitchModeClick(event, mode) {
    console.log(event, mode);
    setLayout(mode);
  }

  function debugPanel() {
    return <div style={{padding: "3px 0"}}>
      <Button size="xs" onClick={(e) => onDebugSwitchModeClick(e, "full")}>Full</Button>
      <Button size="xs" onClick={(e) => onDebugSwitchModeClick(e, "mobile_cards")}>Mobile Cards</Button>
      <Button size="xs" onClick={(e) => onDebugSwitchModeClick(e, "mobile")}>Mobile</Button>
      <Button size="xs" onClick={(e) => onDebugSwitchModeClick(e, "mini")}>Mini</Button>
      <Button size="xs" onClick={(e) => onDebugSwitchModeClick(e, "list_min")}>List Mini</Button>
    </div>;
  }

  // Estabish an AngularJS reference via ngRef, it will hold referenced to current state setters
  if (props.ngref) {
    useEffect(() => {
      acceptNgRef(props.ngref, {
        setProducts: (p) => {
          setVisibleProducts(p);
          setLoading(false);
        },
        setListMode: setListLayout,
        updateLoading
      });

      return () => {
        invalidateNgRef(props.ngref);
      };
    });
  }

  // Templating. This approach uses lazy loading so that only one template is imported.
  // Lazy loaders:
  const ItemFullView = lazy(() => import("./products/product-item/RjsProductItemFullView"));
  const ItemMobileView = lazy(() => import("./products/product-item/RjsProductItemMobileView"));
  const ItemMobileCardsView = lazy(() => import("./products/product-item/RjsProductItemMobileCardsView"));
  const ItemListView = lazy(() => import("./products/product-item/RjsProductItemListView"));
  const ItemListMiniView = lazy(() => import("./products/product-item/RjsProductItemListMiniView"));
  const ItemTableView = lazy(() => import("./products/product-item/RjsProductTableItem"));
  const ItemSearchListView = lazy(() => import("./products/product-item/RjsProductItemSearchListView"));
  const ItemCartListView = lazy(() => import("../react/cart/CartItemsListView"));

  // Call only the importing method needed.
  const getTemplate = () => {
    switch (layout) {
    case "full":
      return [ItemFullView, placeholders.PlaceholderItemFull];
    case "mobile":
      return [ItemMobileView, placeholders.PlaceholderItemMobile];
    case "mobile_cards":
      return [ItemMobileCardsView, placeholders.PlaceholderItemMobileCards];
    case "list":
      return [ItemListView, placeholders.PlaceholderItemList];
    case "list_mini":
      return [ItemListMiniView, placeholders.PlaceholderItemListMini];
    case "table":
      return [ItemTableView, placeholders.PlaceholderItemTable];
    case "search":
      return [ItemSearchListView, placeholders.PlaceholderItemSearch];
    case "cart":
      return [ItemCartListView, placeholders.PlaceholderItemCart];
    default:
      return [ItemFullView, placeholders.PlaceholderItemFull];
    }
  };

  const catalogScrollPositionId = (pathname, filters) => `catalogScrollPosition-${reactLocation.pathname + JSON.stringify(filters)}`;

  const storeScrollPosition = () => {
    if (!props?.autoScroll) return;
    const scrollPosition = window.scrollY;
    sessionStorage.setItem(catalogScrollPositionId(reactLocation, filters), scrollPosition.toString());
  };

  const onProductClick = (e) => {
    storeScrollPosition();
    if (props.onProductClick) props.onProductClick();
  };

  const [ItemViewTemplate, ItemViewPlaceholder] = getTemplate();

  const style = {gridArea: "list"};
  const grid = {};

  const parentProps = {
    theme,
    layout,
    paginator,
    dimensions,
    style,
    grid,
    isBot,
    ItemViewTemplate,
    ItemViewPlaceholder,
    onProductClick,
    callerLocation,
    trackingListName: props.trackingListName,
    itemStyles: props.itemStyles
  };

  const placeholderItemStub = new Array(3).fill("item");
  const STABILIZATION_DELAY_MS = 80;

  const setProductsFromNamedSet = async() => {
    const {productID, categoryId, limitTo, excludeIds, allowCartItems, distinct, minProducts} = props;
    const propOptions = {productID, categoryId, limitTo, excludeIds, allowCartItems, distinct, minProducts};

    // Filter out empty properties.
    const options = Object.keys(propOptions).reduce((result, key) => {
      if (propOptions[key]) result[key] = propOptions[key];
      return result;
    }, {});

    setVisibleProducts(await fetchNamedSet(props.namedSet, options));
  };

  // Load products, depending on the props configuration
  useEffect(() => {
    if (!rootRef.current) return;

    if (props.products?.length) setVisibleProducts(props.products);
    updateDimensions();
  }, [props?.products]);

  useEffect(() => {
    if (props?.namedSet) {
      setProductsFromNamedSet();
    }
  }, [props?.namedSet]);

  useEffect(() => {
    if (!rootRef.current) return;

    setListLayout(layout);
  }, [layout]);

  useEffect(() => {
    if (!rootRef.current) return;

    setListLayout(props.layout);
  }, [props?.layout]);

  useEffect(() => {
    if (!rootRef.current) return;

    let productsTotalCount = null;
    if (paginator) {
      productsTotalCount = paginator.pagination.total_count;
    }

    const listItemId = props?.trackingListName;
    const eventName = productsTotalCount ? "search_page" : "no_result_page";
    const urlParams = new URLSearchParams(location.search);
    const searchTerm = urlParams.get("keywords");

    if (productsTotalCount || productsTotalCount === 0) {
      if (searchTerm && eventName) {
        trackPageView(eventName, {searchTerm, productsTotalCount, products, list_item_id: "search_page", list_item_name: searchTerm});
      }
    } else if (products.length) {
      const shouldTrack = !window.CatalogServiceHelper?.isCatalogPage() || listItemId === "search_suggestions";
      const listItemName = listItemId === "search_suggestions" ? props.keywords : searchTerm || listItemId;
      if (shouldTrack) trackEvent("view_item_list", {list_item_id: listItemId, list_item_name: listItemName, products});
    }
  }, [products?.length]);

  useEffect(() => {
    if (!rootRef.current) return;

    if (!props?.autoScroll) return;
    const savedScrollPosition = parseInt(sessionStorage.getItem(catalogScrollPositionId(reactLocation, filters)), 10);

    if (products.length > 0 && !isNaN(savedScrollPosition)) {
      requestAnimationFrame(() => {
        window.scrollTo(0, savedScrollPosition);
        setTimeout(() => {
          window.scrollTo(0, savedScrollPosition);
        }, STABILIZATION_DELAY_MS);
      });
    }
  }, [products, reactLocation.pathname]);

  useEffect(() => {
    return () => {
      rootRef.current = false;
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={"row"}>
        <div className={"col-sm-12"}>
          <ProductListWrapper ref={rootRef}>
            {debug && debugPanel()}

            {layout === "table" && <RjsProductTable className='product-table'/>}
            <ProductList className='product-list' cardWidth={cardWidth} listStyles={props.listStyles} layout={layout}>
              {products.length > 0 && products.map((product, index) => (
                <RjsProductItem key={`${product.id}`} product={product} {...parentProps} positionInList={index} itemStyles={props.itemStyles}/>
              ))}
              {isLoading && placeholderItemStub.map((item, index) => (
                <ItemViewPlaceholder className="item-placeholder" key={index} animationKey={index} />
              ))}
            </ProductList>

          </ProductListWrapper>
          {hasMoreProducts && !hideMoreButton && (
            <ButtonMoreProducts onClick={() => loadMoreProducts(filters)}>
              {t('load_more')}
            </ButtonMoreProducts>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default RjsProductList;

const ProductListWrapper = styled.div``;

const ProductList = styled.div.attrs(props => ({
  style: {
    gridTemplateColumns: props.layout === "search" ? null : `repeat(auto-fill, minmax(${props.cardWidth}, 1fr))`,
    ...props.listStyles
  }
}))`
  display: grid;
  gap: 15px 10px
`;

const ButtonMoreProducts = styled.button`
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    background-color: #f4991a;
    padding: 10px 30px;
    display: block;
    margin: 5% auto;
    border: none;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
`;
