import axios from "axios";

const apiEndpoints = {
  frontend: "/api/frontend",
  products: "/api/products",
  frontendProducts: "/api/frontend/products",
  frontendOrders: "/api/frontend/orders",
  toggleFavorites: (productId) => axios.post(`/api/frontend/favorites/${productId}/toggle.json`),
  location: {
    zipcodeSubmit: (requestBody) => axios.post("/hubs/apply.json", requestBody),
    zipcodeInspect: (zipcode) => axios.get(`/api/frontend/hubs/from_zipcode?zipcode=${zipcode}`),
    zipcodeInfo: (zipcode) => axios.get("/api/frontend/hubs/zipcode_info.json", {params: {zipcode}}),
    deliveryTerms: (requestParams) => axios.get("/api/frontend/hubs/delivery_terms.json", requestParams)
  },
  user: {
    login: (requestBody) => axios.post("/login.json", requestBody)
  }
};

export default apiEndpoints;
