import globalState from "../globalState";
import * as _ from "underscore";
import AngularIntegration from "../../angular-integration";

const sessionGlobalState = () => {
  const {$on, $broadcast} = AngularIntegration;
  const {getGlobalState, setGlobalState} = globalState();

  const eventHandlers = {};
  const stateName = "session";
  let mounted = true;
  let sessionState;

  const initialize = () => {
    const userService = window.UserService;
    const PromotionsService = window.PromotionsService;

    const tokenizedUser = {
      userToken: window.currentUserToken
    };
    const globalSession = getGlobalState(stateName);
    sessionState = {
      currentUser: userService?.currentUser,
      sessionContext: null,
      masterFilters: null,
      topTaxonFilters: null,
      setCurrentUser: () => {},
      setTopTaxonFilters: () => {},
      setMasterFilters: () => {},
      setSessionContext: () => {},
      updateNotifications: () => {},
      notifications: [],
      currentHub: window.currentHubName,
      currentZipcode: window.currentZipcode,
      marketingZone: window.currentMarketingZone,
      currency: "CHF",
      ...globalSession
    };
    if (!globalSession) setGlobalState(stateName, sessionState);

    if (userService?.currentUser) {
      const user = userService?.currentUser?.id ? userService.currentUser : tokenizedUser;
      setCurrentUser(completeCurrentUser(user));
    }

    $on("user:updated", (event, response) => {
      if (mounted) {
        setCurrentUser(completeCurrentUser(response?.user) || {});
      }
    }).then(handler => {
      eventHandlers["user:updated"] = handler;
    });

    $on("search:update", (event, response) => {
      if (mounted) {
        setSessionState({currentSearch: {...response} || null});
      }
    }).then(handler => {
      eventHandlers["search:update"] = handler;
    });

    $on("promo:updated", (event, response) => {
      if (mounted) {
        setSessionState({currentPromotion: {...response.promotion} || {}});
      }
    }).then(handler => {
      eventHandlers["promo:updated"] = handler;
    });

    if (PromotionsService?.currentPromotion) {
      setSessionState({currentPromotion: PromotionsService?.currentPromotion || {}});
    }
  };

  const setSessionState = (newState) => {
    const candidateNewState = {...sessionState, ...newState};

    if (!_.isEqual(candidateNewState, sessionState)) {
      sessionState = {...candidateNewState};
      setGlobalState(stateName, sessionState);
    }
  };

  // Public instances:
  const setCurrentHub = (currentHub) => {
    setSessionState({currentHub});
  };

  const setCurrentZipcode = (currentZipcode) => {
    if (!currentZipcode) return;

    setSessionState({currentZipcode});
    if (currentZipcode) $broadcast("react:zipcode:changed", {zipcode: currentZipcode});
  };

  const setMarketingZone = (marketingZone) => {
    setSessionState({marketingZone});
  };

  const setCurrentSearch = (currentSearch) => {
    setSessionState({currentSearch});
  };

  // Added a setter function for updating currentUser
  const setCurrentUser = (currentUser) => {
    setSessionState({currentUser});
  };

  const setTopTaxonFilters = (topTaxonFilters) => {
    setSessionState({topTaxonFilters});
  };

  const setMasterFilters = (masterFilters) => {
    setSessionState({masterFilters});
  };

  const setSessionContext = (sessionContext) => {
    setSessionState({sessionContext});
  };

  const updateNotifications = (notifications) => {
    setSessionState({notifications});
  };

  const completeCurrentUser = (user) => {
    const SmartPassService = window.SmartPassService;
    
    if (Object.keys(user).length === 0) return {};
    // TODO Mike: remove the lines below when the fix ticket is done.
    // HACK alert: while we solve this ticket => https://farmyag.atlassian.net/browse/IM-8515
    // farmy_family info is inside "additional_attributes".
    const farmy_family = user?.farmy_family || user?.additional_attributes?.farmy_family || null;
    // !todo

    const canSeeFarmyFamily = true;

    // SmartPass
    const smartPass = SmartPassService?.hasActiveSubscription
      ? {
        type: window.currentSmartPassType,
        level: window.currentSmartPassLevel,
        until: window.currentSmartPassUntilDate
      }
      : null;

    const hasShippingSubscription = SmartPassService?.hasShippingSubscription;
    const hasActiveSubscription = SmartPassService?.hasActiveSubscription;

    return {...user, farmy_family, smartPass, hasShippingSubscription, hasActiveSubscription, canSeeFarmyFamily: canSeeFarmyFamily};
  };

  initialize();

  return {
    ...sessionState,
    setCurrentUser,
    setCurrentSearch,
    setTopTaxonFilters,
    setMasterFilters,
    setSessionContext,
    setSessionState,
    updateNotifications,
    setCurrentHub,
    setCurrentZipcode,
    setMarketingZone
  };
};

export default sessionGlobalState;
