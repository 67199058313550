import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import currentTheme from "../react-themes/theme";
import {translate} from "../shared-services/translate";

const CheckboxFilterWrapper = styled.div`
  a.show-all {
    font-size: 12px;
    position: relative;
    left: -4px;
  }
`;

const FilterValuesList = styled.ul`
  & > li.checkbox-filter-value.checkbox.id_46.awesome-checkbox {
    label {
      text-transform: capitalize;
    }
  }
`;

export class RjsFilterCheckboxes extends React.Component {
  constructor(props) {
    super(props);

    const scope = this;

    this.state = {
      minimized: false,
      minimizing: props.minimizing || true,
      isLoading: false,
      showAll: true,
      reducedItemMaxCount: props.reducedItemMaxCount || 5, // The number of items to show when showAll is false
      selections: props.selections || {},
      browser: {},
      elementHeight: 0
    };

    this.filterRef = React.createRef();

    AngularIntegration.$on('catalog:loading', () => {
      scope.setState({isLoading: true})
    }).then(unsubscribe => scope._unbindOnCatalogLoading = unsubscribe);
    AngularIntegration.$on('catalog:loaded', () => {
      scope.setState({isLoading: false}, scope.onFiltersChanged.bind(scope));
    }).then(unsubscribe => scope._unbindOnCatalogLoaded = unsubscribe);
    AngularIntegration.$on('filters:postchange', scope.onFiltersChanged.bind(scope))
      .then(unsubscribe => scope._unbindOnFiltersPostchange = unsubscribe);
    AngularIntegration.$on('catalog:filters:changed', scope.onFiltersChanged.bind(scope))
      .then(unsubscribe => scope._unbindOnFiltersPostchange = unsubscribe);
  }

  componentDidMount() {
    this.setBrowser();
    this.calculateHeight(true);
  }

  componentWillUnmount() {
    if (this._unbindOnCatalogLoading) this._unbindOnCatalogLoading();
    if (this._unbindOnCatalogLoaded) this._unbindOnCatalogLoaded();
    if (this._unbindOnFiltersChanged) this._unbindOnFiltersChanged();
  }

  neverHide() {
    return this.state.family
  }

  calculateHeight(hideIfLong = false, callback = null) {
    const node = this.filterRef.current;
    if (!node) return;

    node.style.height = 'auto';

    const numRealValues = this.props.filter?.values && this.props.filter.values.filter(v => v.depth == this.props.taxonDepth).length;

    this.setState({
      elementHeight: 'auto'
    }, () => {
      let scrollHeight = node.scrollHeight;

      if (!this.neverHide() && hideIfLong) {
        if (numRealValues > this.state.reducedItemMaxCount) {
          this.setState({ showAll: false }, () => {
            let scrollHeight = node.scrollHeight;
            this.setState({elementHeight: `${scrollHeight}px`}, callback)
          })
          // this.toggleMinimize(true);
        } else if (this.state.minimized) { // if there's enough space, do not minimize
          this.toggleMinimize();
          this.setState({elementHeight: `${scrollHeight}px`}, callback)
        } else if (!this.state.showAll) { // if there's enough space and it's reduced, show all options
          this.onShowAll();
          this.setState({elementHeight: `${scrollHeight}px`}, callback)
        }
      } else {
        this.setState({elementHeight: `${scrollHeight}px`}, callback)
      }
    })
  }

  onCatalogLoaded() {
    this.calculateHeight(true);
  }

  toggleMinimize(forceMinimize = false) {
    this.setState({minimized: forceMinimize === true ? true : !this.state.minimized})
  }

  setBrowser() {
    let userAgent = (navigator && navigator.userAgent) || '';
    let browser = {
      isFirefox: userAgent.indexOf('Firefox') !== -1,
      isChrome: userAgent.indexOf('Chrome') !== -1
    };

    browser.isSafari = !browser.isChrome && userAgent.indexOf('Safari') !== -1;
    this.setState({browser: browser})
  }

  onShowAll() {
    this.setState({showAll: true, elementHeight: 'auto'});
  }

  onFiltersChanged(event, options) {
    options = options || {};
    options.selections = options.selections || this.state.selections;
    if (options.selections && options.selections[this.props.filter.search_param]) {
      this.setState({selections: options.selections[this.props.filter.search_param]}, (() => {
        this.calculateHeight(true)
      }).bind(this));
    } else this.setState({r: new Date()}, (() => {
      this.calculateHeight(true)
    }).bind(this)); // ...or just provoke a state refresh
  }

  onSelectionChange(value) {
    let selections = this.state.selections;
    let selectionKeys = _(_(selections).keys()).filter((key) => {
      return key != value.value
    });

    if (this.props.singular) {
      _(selectionKeys).each((key) => {
        delete selections[key]
      })
    }

    selections[value.value] = !selections[value.value];

    if (!selections[value.value]) {
      delete (selections[value.value])
    }

    let selected = selections[value.value] || false;

    if (!selected)
      selectionKeys.push(value.value);

    this.setState({selections: selections});

    if (window.CatalogFilterCtrl) {
      if (window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param] == null)
        window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param] = {}

      if (selected) {
        window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param][value.value] = true;
        if (this.props.singular)
          _(selectionKeys).each((key) => {
            delete (window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param][key]);
          })
      } else {
        if (this.props.singular)
          _(selectionKeys).each((key) => {
            delete (window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param][key]);
          });
        else
          delete (window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param][value.value]);
      }
    }

    CatalogServiceHelper.onFilterSelectionChange();

    if (window.CatalogViewCtrl) window.CatalogViewCtrl.update({resetPage: true, resetProducts: true});
    if (window.CatalogSearchViewCtrl) window.CatalogSearchViewCtrl.update({resetPage: true});
    if (window.SupplierPageViewCtrl) window.SupplierPageViewCtrl.update({resetPage: true});

    AngularIntegration.$broadcast('filters:postchange', {filter: this.props.filter, selections: selections});

    return false;
  }

  render(props) {
    var wrapperClass = "filter-wrapper";

    var toggleIconClass = "fa fa-caret-right";
    if (!this.state.minimized) toggleIconClass += " open";

    var valueElements = [];

    const onShowAll = this.onShowAll.bind(this);

    // TODO: Apply sorting orderBy:checkboxFilterCtrl.orderingExpression | limitTo:checkboxFilterCtrl.limitExpression:0
    var values = this.props.filter?.values;

    if (this.props.taxonDepth != null) {
      values = _.filter(this.props.filter?.values, v => v.depth == this.props.taxonDepth);
    } else {
      values = this.props.filter?.values;
    }

    if (this.props.sorting == "name_asc") {
      values = _.sortBy(values, v => v.name.toLowerCase());
    } else if (this.props.sorting == "results_desc") {
      if (this.state.showAll && this.props.showFirstCount) { // always sort by alpha in 'show all' state for minimized-by-default
        values = _.sortBy(values, v => v.name.toLowerCase());
      } else {
        values = _.sortBy(values, v => -v.result_count);
      }
    }

    // Filter out empty filter options with no results
    let realValues = values?.filter(val => (val.result_count != 0 && !val._hidden) || this.state.selections[val.value]) || 0;

    // If the filter is in reduced mode, show only top {reducedItemMaxCount} values with the most results
    if (!this.state.showAll && realValues.length > this.state.reducedItemMaxCount) {
      if (this.props.filter.search_param === "certificate_ids") {
        realValues = realValues.slice(0, this.state.reducedItemMaxCount);
      } else {
        realValues = realValues.sort((a, b) => b.result_count - a.result_count).slice(0, this.state.reducedItemMaxCount);
      }
    }

    for (var i = 0; i < realValues.length; i++) {
      let val = realValues[i];

      let labelClass = this.state.browser.isChrome ? "" : "browser-fix";
      if (val.length >= 24) labelClass = 'tight-1';
      else if (val.length >= 28) labelClass = 'tight-2';
      else if (val.length >= 33) labelClass = 'tight-3';

      let labelId = `k_${this.props.filter.search_param}_v_${val.value}`

      valueElements.push(
        <li className={`checkbox-filter-value checkbox awesome-checkbox id_${val.value}`} key={labelId}>
          <input disabled={this.state.isLoading} type="checkbox" value={val.value} id={labelId}
                 onChange={this.onSelectionChange.bind(this, val)}
                 checked={this.state.selections[val.value] || false}
          />
          <label htmlFor={labelId} className={labelClass}>
            {val.name}
            <span className="result-count">[{val.result_count}]</span>
          </label>
        </li>)

    }

    let hasValues = _.filter(realValues, v => v.result_count > 0).length > 0;

    return <CheckboxFilterWrapper
      ref={this.filterRef}
      className={`filter-component ${this.state.minimized ? 'minimized' : ''} ${hasValues ? '' : 'invisible'}`}
    >{hasValues &&
    <div className={wrapperClass}>
      {(this.props.filter?.display_name && this.props.filter?.display_name?.length) &&
        <div className={`title-wrapper ${this.state.minimizing ? 'clickable' : ''}`}
          onClick={this.state.minimizing ? this.toggleMinimize.bind(this) : undefined}>
          <div className='title-inner'>
            <h5 className="filter-title">
              <span>{this.props.filter.display_name}</span>
            </h5>
          </div>
          <div className="toggle-icon">
            {this.state.minimizing ? <i className={toggleIconClass} ng-show=""></i> : null}
          </div>
        </div>
      }

      <FilterValuesList className={"filter filter-type-checkbox filter-type-" + this.props.filter.search_param}>
        {valueElements}
      </FilterValuesList>
      <div className="clearfix"></div>
      {!this.state.showAll && <a href='#' className={'show-all'} onClick={onShowAll}>{translate('search_form.show_more_results')}</a>}
    </div>
    }<div className={"clearfix"}></div></CheckboxFilterWrapper>;
  }

  // componentDidCatch(error, info) {
  //   console.log(error, info);
  //   super.componentDidCatch(error, info);
  // }
}
