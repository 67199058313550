import globalState from "../globalState";
import * as _ from "underscore";

const routerState = () => {
  const {getGlobalState, setGlobalState} = globalState();
  const stateName = "router";

  const globalRouter = getGlobalState(stateName);
  let routerState = {
    pageCode: null,
    setPageCode: () => {},
    isProductPage: false,
    ...globalRouter
  };

  const setRouterState = (newState) => {
    const currentState = getGlobalState(stateName);
    const candidateNewState = {...currentState, ...newState};

    if (!_.isEqual(candidateNewState, currentState)) {
      routerState = {...candidateNewState};
      setGlobalState(stateName, routerState);
    }
  };

  const setPageCode = (pageCode) => setRouterState({pageCode});
  const removePageCode = () => setPageCode(null);

  return {
    ...routerState,
    setPageCode,
    removePageCode
  };
};
export default routerState;
