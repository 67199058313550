import globalState from "../globalState";
import cartParser, {cartComparator} from "../../react/cart/cartParser";
import AngularIntegration from "../../angular-integration";

const cartGlobalState = () => {
  const {getGlobalState, setGlobalState} = globalState();
  const {didCartChange} = cartComparator();
  const {$on, $broadcast} = AngularIntegration;
  const parseCart = cartParser();
  const stateName = "cart";

  let broadcast = true;
  let mounted = true;
  const eventHandlers = {};

  const getCartState = () => getGlobalState("cart");

  const globalCartState = getCartState();
  const cartState = {
    cartLoadRequested: false,
    setCurrentCart: () => {},
    ...globalCartState
  };

  const setCartState = (newState, shouldBroadcast = broadcast) => {
    const cartState = {...getCartState(), ...newState};
    setGlobalState(stateName, cartState);
  };

  const setCurrentCart = (cart, options = {isLocalUpdate: true, save: true}, shouldBroadcast = broadcast) => {
    const {isLocalUpdate, save} = options;
    const cartState = getCartState();

    if (didCartChange(cartState?.currentCart, cart)) {
      setCartState({currentCart: parseCart(cart, {isLocalUpdate})});
      if (shouldBroadcast) $broadcast("cart:updated", cart);
      else enableBroadcast();
    }
  };

  const loadCart = () => {
    setGlobalState("cart", {cartLoadRequested: true});
  };

  const signalCartWasLoaded = () => {
    setCartState({cartLoadRequested: false});
  };

  const enableBroadcast = () => {
    broadcast = true;
  };
  const disableBroadcast = () => {
    broadcast = false;
  };

  const uponUnmount = () => {
    mounted = false;
    Object.keys(eventHandlers).forEach(key => eventHandlers[key]());
  };

  // Event response
  $on("cart:changed", (event, response) => {
    const cart = response?.cart?.cart || response?.cart || response;
    if (mounted && cart?.id) {
      disableBroadcast();
      setCurrentCart(cart || {});
    }
  }).then(handler => {
    eventHandlers["cart:changed"] = handler;
  });

  return {...cartState, setCurrentCart, loadCart, signalCartWasLoaded, uponUnmount};
};

export default cartGlobalState;
