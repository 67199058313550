import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import RjsFilterCategories from './RjsFilterCategories.jsx'
import {RjsFilterCheckboxes} from './RjsFilterCheckboxes.jsx'
import {RjsFilterFarmyFamily} from './RjsFilterFarmyFamily.jsx'

export class RjsFilterPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: props.filters || [],
      frozenCategoryFilter: {},
    };

    this.ngrFilterPanel = props.ngrFilterPanel;
    this.$http = props.$http;
    this.firstLoad = true;

    // console.log(RjsFilterPanel, this.props, props);

    var scope = this;

    AngularIntegration.$on('catalog:filters:loaded', this.onFiltersLoaded.bind(this)).then(unsubscribe => scope._unbindOnCatalogLoaded = unsubscribe);
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    if (this._unbindOnCatalogLoaded) this._unbindOnCatalogLoaded();
  }

  onFiltersLoaded(event, options) {
    // viewmode will indicate whether it's a catalog, search or supplier page context
    this.setState({ filters: options.filters, viewMode: options.viewmode });
  }

  render(props) {
    let filterBlocks = [];

    _.sortBy(this.state.filters, f => -1 * (f.display_priority || 1000)).forEach(filter => {
      let selections = (window.CatalogFilterCtrl && window.CatalogFilterCtrl.urlFilterParameters) ? window.CatalogFilterCtrl.urlFilterParameters[filter.search_param] : null;

      if (filter.search_param == "taxon_ids") {
        if (this.props.sidebarMode) {
          // Categories block is not shown in mobile-sidebar mode (because category tree is accessible via the main sidebar menu)
        } else {
          // Support categories as checkboxes for use in search mode and supplier view mode
          if (this.props.categoryCheckboxes) {
            const taxonDepth = this.props.taxonDepth || 1;
            filterBlocks.push(
              <RjsFilterCheckboxes
                filter={filter}
                filterPanel={this}
                minimizing={false}
                sorting="name_asc"
                selections={selections}
                taxonDepth={taxonDepth}
                key={filter.search_param}
              ></RjsFilterCheckboxes>);
          } else {
            filterBlocks.push(
              <RjsFilterCategories
                filter={filter}
                filterPanel={this}
                minimizing={false}
                sorting="position"
                selections={selections}
                key={filter.search_param}
              ></RjsFilterCategories>);
          }
        }
      } else if (filter.search_param == "supplier_id") {
        if (this.state.viewMode != "supplier") {
          filterBlocks.push(<RjsFilterCheckboxes filter={filter} filterPanel={this} minimizing={false} sorting="results_desc" showFirstCount={7} selections={selections} key={filter.search_param}></RjsFilterCheckboxes>)
        }
      } else if (filter.display_name && filter.display_name.toLowerCase().indexOf("farmy family") > -1) { // Exception for the Farmy Family filter
        filterBlocks.push(
          <RjsFilterFarmyFamily
            filter={filter}
            filterPanel={this}
            minimizing={false}
            sorting="results_desc"
            showFirstCount={7}
            selections={selections}
            key={filter.search_param}>
          </RjsFilterFarmyFamily>
        );
      } else {
        filterBlocks.push(
          <RjsFilterCheckboxes
            filter={filter}
            filterPanel={this}
            minimizing={false}
            sorting={filter.search_param === "certificate_ids" ? null : "name_asc"}
            selections={selections}
            reducedItemMaxCount={filter.search_param === "certificate_ids" ? 8 : null}
            key={filter.search_param}>
          </RjsFilterCheckboxes>
        );
      }
    });

    // console.log('render', props);

    return (
      <div>
        <div>{filterBlocks}</div>
      </div>
    );
  }

  renderFilterBlock(filter) {
    return
  }

  // componentDidCatch(error, info) {
  //   console.log(error, info);
  //   super.componentDidCatch(error, info);
  // }
}