import * as _ from 'underscore';
import * as sprintf from 'sprintf';
import {trackEvent} from '../services/tracking-helper';

'use strict';

angular.module('app')
  .directive('farmyCatalogMasterFilters', function() {
    return {
      controller: ['$rootScope', '$scope', '$http', '$q', '$timeout', '$attrs', '$location', '$element', 'TaxonHelper', 'CatalogServiceHelper', 'UserService', '$localStorage', function ($rootScope, $scope, $http, $q, $timeout, $attrs, $location, $element, TaxonHelper, CatalogServiceHelper, UserService, $localStorage) {
        window.farmyCatalogMasterFilters = $scope;
        $scope.currentStorefront = window.currentStorefront;

        function constructor() {
          $scope.$watch('CatalogServiceHelper.currentTaxon', (newValue, oldValue) => {

          });

          $scope.$watch('masterFilters', (newValue, oldValue) => {
            if (_.isEqual(newValue, oldValue)) return;

            $rootScope.$broadcast('catalog:filters:master:changed', {masterFilters: $scope.masterFilters, filterSettings: $scope.getFilterSettings});
            CatalogServiceHelper.onFilterSelectionChange();

            UserService.updateFilterPrefs(newValue); // save preferences for the user
          }, true);

          $rootScope.$on('user:authenticated', loadMasterFilters);

          if ($scope.mode == null || $scope.mode == "") {
            $scope.mode = window.isMobile ? 'mobile' : 'desktop';
          }

          // Enforce class
          $element.removeClass('mode-desktop');
          $element.removeClass('mode-mobile');
          $element.addClass(`mode-${$scope.mode}`);

          loadMasterFilters();

          loadQueryParams();

          // Get prefs from the user account, on load
          $rootScope.$on('user:loaded', (event, opts) => {
            if (opts.user) {
              for(var key in opts.user.filter_prefs) {
                // Tmp fix for stupid typo
                if (key == 'only_vegetarian') continue;

                if (opts.user.filter_prefs[key])
                  $scope.masterFilters[key] = true;
                else $scope.masterFilters[key] = false;
              }
            }
          });
        }

        function destructor() {
          window.farmyCatalogMasterFilters = null;
        }

        // Rely on $localStorage for user-less filter persistence
        // If user logs in afterwards, combine the current selection with the stored one.
        function loadMasterFilters() {
          $rootScope.masterFiltersFirstLoad = $rootScope.masterFiltersFirstLoad == undefined ? true : $rootScope.masterFiltersFirstLoad;

          if (UserService.currentUser && UserService.currentUser.filter_prefs) {
            if ($rootScope.masterFiltersFirstLoad) {
              $scope.masterFilters = $localStorage.filterPrefs = UserService.currentUser.filter_prefs;
              $rootScope.masterFiltersFirstLoad = false;
            } else
              $scope.masterFilters = $localStorage.filterPrefs = angular.extend(UserService.currentUser.filter_prefs, _($localStorage.filterPrefs).pick((val, key) => val));
          } else {
            let baseFilters = {};
            if ($rootScope.masterFiltersFirstLoad) {
              baseFilters = {
                only_bio: false,
                only_local: false,
                only_gluten_free: false,
                only_lactose_free: false,
                only_vegeterian: false,
                only_vegan: false
              };
            }

            $scope.masterFilters = $scope.masterFilters || {};
            $localStorage.filterPrefs = $localStorage.filterPrefs || {};

            angular.extend($scope.masterFilters, baseFilters, $localStorage.filterPrefs);

            $localStorage.filterPrefs = $scope.masterFilters;
            $rootScope.masterFiltersFirstLoad = false;
          }
        }

        // Get master filter states from URL params
        function loadQueryParams() {
          var queryFilterParams = CatalogServiceHelper.deserializeUrlParamsToFilterSelection($location.search());


          for(var key in queryFilterParams) {
            if (key.indexOf('master_') == -1)
              continue;

            if (!queryFilterParams[key])
              continue;

            var mKey = key.replace('master_', '');

            if ($scope.masterFilters[mKey] !== null && $scope.masterFilters[mKey] !== undefined)
              $scope.masterFilters[mKey] = true;
          }
        }

        // Public members

        $scope.CatalogServiceHelper = CatalogServiceHelper;

        $scope.getFilterSettings = function() {
          const params = {};

          // if ($scope.masterFilters.only_bio) {
          //   if (params['certificate_ids'] == null) params['certificate_ids'] = [];
          //   params['certificate_ids'] = params['certificate_ids'].concat([17, 3, 5]);
          // }
          //
          // if ($scope.masterFilters.only_local) {
          //   if (params['certificate_ids'] == null) params['certificate_ids'] = [];
          //   params['certificate_ids'] = params['certificate_ids'].concat([15]);
          // }
          //
          // if ($scope.masterFilters.only_gluten_free) {
          //   if (params['certificate_ids'] == null) params['certificate_ids'] = [];
          //   params['certificate_ids'] = params['certificate_ids'].concat([13]);
          // }
          //
          // if ($scope.masterFilters.only_lactose_free) {
          //   if (params['certificate_ids'] == null) params['certificate_ids'] = [];
          //   params['certificate_ids'] = params['certificate_ids'].concat([14]);
          // }
          //
          // if ($scope.masterFilters.only_vegan) {
          //   if (params['certificate_ids'] == null) params['certificate_ids'] = [];
          //   params['certificate_ids'] = params['certificate_ids'].concat([11]);
          // }
          //
          // if ($scope.masterFilters.only_vegeterian) {
          //   if (params['certificate_ids'] == null) params['certificate_ids'] = [];
          //   params['certificate_ids'] = params['certificate_ids'].concat([12]);
          // }

          for (const key in $scope.masterFilters) {
            if ($scope.masterFilters[key]) {
              params[`master_${key}`] = true;
            }
          }

          return params;
        };

        $scope.onMasterFilterChanged = function(filterName) {
          $timeout(() => {
            var enabledPrefs = [];

            for(var k in $scope.masterFilters) {
              if ($scope.masterFilters[k])
                enabledPrefs.push(k);
            }

            if (enabledPrefs.includes(filterName))
              trackEvent('category_filter', {gtmObject: {filter: filterName}});

            ahoy.track("catalog-master-filter-change", { clicked: filterName, enabled: enabledPrefs.sort().join(','), taxon_id: (window.CatalogViewCtrl && window.CatalogViewCtrl.currentTaxonId) || window.currentTaxonId, channel: window.xSessionChannel });
          }, 150)
        };

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {
        mode: '@'
      },
      link: function($scope, $element, attributes) {

      },
      templateUrl: function() { return `/ng/templates/taxons/master_filters.html?locale=${window.I18n.locale}` }
    }
  });


