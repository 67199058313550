const userParser = (mode = "default") => {
  const parsers = {
    default: (user = {}) => {
      if (!user || Object.keys(user).length === 0) return null;

      const {SmartPassService} = window;

      const isAdmin = user.roles && user.roles.includes("admin");

      // farmy_family info is inside "additional_attributes".
      const farmy_family = user?.farmy_family || user?.additional_attributes?.farmy_family || null;

      const canSeeFarmyFamily = true;

      // SmartPass
      const smartPass = SmartPassService?.hasActiveSubscription
        ? {
          type: window.currentSmartPassType,
          level: window.currentSmartPassLevel,
          until: window.currentSmartPassUntilDate
        }
        : null;

      const hasShippingSubscription = SmartPassService?.hasShippingSubscription;
      const hasActiveSubscription = SmartPassService?.hasActiveSubscription;

      return {...user, isAdmin, farmy_family, farmyFamily: farmy_family, smartPass, hasShippingSubscription, hasActiveSubscription, canSeeFarmyFamily};
    }
  };

  return ((mode) => parsers[mode])(mode);
};

export default userParser;
